.box {
  display: inline-block;
  width: 100%;
  height: fit-content;
  aspect-ratio: 1/1;
  -webkit-mask: url(../assets/javali.png) center/cover;
  mask: url(../assets/javali.png) center/cover;
  background: linear-gradient(-45deg, #A141AD, #FF415A);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

/* maintain the square ratio */
.box::before {
  content: "";
  display: block;
  padding-top: 100%;
}

/**/


@keyframes change {
  to {
    background-position: right;
  }
}