@font-face {
  font-family: TrashHand;
  src: url('./assets/TrashHand.TTF') format('TrueType'),
}

@font-face {
  font-family: PlatNomor;
  src: url('./assets/PlatNomor-WyVnn.ttf') format('TrueType'),
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  margin: 0;

  color: #fff;
  /* background: linear-gradient(173.01deg, #FCEEE3 1.04%, #FFFEFC 1.05%, #FCE4DF 121.8%); */
  /* background: url(https://assets.getpartiful.com/backgrounds/aquamarine/web.jpg), linear-gradient(to right top, rgb(127, 221, 221) 0%, 22%, rgb(192, 227, 227) 30%, 50%, rgb(134, 206, 223) 60%, 75%, rgb(105, 154, 235) 92%); */
  background: #232323;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


#root {
  width: 100%;
  height: 100svh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'TrashHand';
}

.no-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.bg-gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
}

.gradient-text {
  font-family: "TrashHand", sans-serif;
  font-weight: 400;
  font-style: normal;
  background: linear-gradient(-45deg, #A141AD, #FF415A);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.spin {
  animation: spin 40s linear infinite;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
*::-webkit-scrollbar {
  display: none;
  width: 0 !important
}

/* For IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

* {
  scrollbar-width: none;
}

.paytone-one-regular {
  font-family: "Paytone One", sans-serif;
  font-weight: 400;
  font-style: normal;
  background: linear-gradient(-45deg, #050CA5, #00B8D5);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}